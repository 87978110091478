<template>
    <b-card no-body>
        <b-row class="justify-content-center">
            <h5 class="font-weight-bolder">Filter By Role</h5>
        </b-row>
        <b-row class="justify-content-center">
            <b-col cols="12" md="5" class="mb-md-0 mb-2">
                <!-- <label>Roles</label> -->
                <v-select v-model="form.role_id.val" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="roles" :reduce="(val) => val.id" :clearable="true" input-id="media-type" label="name" placeholder="Choose to Filter..."/>
            </b-col>
        </b-row>
        <b-row class="justify-content-center">
            <!-- <b-col cols="12" md="4" class="mb-md-0 mb-2"> -->
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="button" variant="danger" class="mt-1" @click="filterSpecialItems"> Filter </b-button>
            <!-- </b-col> -->
        </b-row>
        <!-- </b-row> -->
    </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import useSpecialItemList from "@/views/apps/special-item/useSpecialItemList";
import { avatarText } from "@core/utils/filter";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormInput,
        BButton,
        vSelect,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters("appSection", ["getAppSections"]),
        getAppSections1() {
            return this.getAppSections.filter((item) => {
                return ["videos"].includes(item.summary);
            });
        },
    },
    props: {
        roles: Array,
    },
    setup() {
        const isLoading = ref();
        const appSectionTypes = ref([
            {
                label: "old version - artist type",
                name: "artist",
            },
            {
                label: "new version - composer type",
                name: "composer",
            },
        ]);

        const {
            fetchSpecialItem,
            tableColumns,
            perPage,
            pagination,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            specialItems,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        } = useSpecialItemList();

        const blankForm = {
            role_id: {
                type: "in",
                val: null,
            },
        };
        const form = ref(blankForm);

        return {
            form,
            isLoading,
            // Sidebar
            fetchSpecialItem,
            pagination,
            tableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            specialItems,
            appSectionTypes,
            // Filter
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        };
    },
    methods: {
        ...mapActions("appSection", ["loadAppSection"]),
        filterSpecialItems() {
            this.$emit("filter", { page: 1, itemsPerPage: this.perPage }, this.form);
        },
    },
    created() {
        this.loadAppSection();
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
