<template>
    <div>
        <b-card>
            <h4 class="font-weight-bolder mb-2">Assign a Role</h4>
            <b-row class="justify-content-center">
                <b-col cols="6">
                    <b-form-group label="Users" label-for="user">
                        <v-select v-model="userIds" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="users" label="user_name" :clearable="false" input-id="user" @search="filterUsers" placeholder="Select User..." />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group label="Role" label-for="user">
                        <v-select v-model="roleId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="roles" label="name" :clearable="false" input-id="user" @search="filterUsers" placeholder="Select Role..." />
                    </b-form-group>
                </b-col>
                <b-col cols="1">
                    <div class="d-flex mt-2">
                        <b-button @click="asignePermission" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2"> assign </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <h4 class="font-weight-bolder mb-2">Remove a Role</h4>
            <div class="row justify-content-center">
                <assign-role-list-filters class="col-8" @filter="fetchUserRoles" :roles="roles" />
            </div>
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <!-- <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePageSpecialItems({ page: 1, itemsPerPage: $event })" />
                        <label>entries</label>
                    </b-col> -->
                </b-row>
            </div>

            <b-table ref="refUserListTable" class="position-relative pb-3" :items="userRole" responsive :per-page="perPage" :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found">
                <!-- Column: Item -->
                <template #cell(user_name)="data">
                    <span class="font-weight-bolder"> {{ data.item.user_name }} </span>
                </template>
                <template #cell(name)="data">
                    <b-badge variant="light-info"> {{ data.item.name }} </b-badge>
                </template>
                <!-- Column: Media -->
                <template #cell(role)="data">
                    <b-badge variant="primary" class="badge-glow"> {{ data.item.role[0].name }} </b-badge>
                </template>
                >
                <template #cell(actions)="data">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" pill @click="removeRoleHandler(data.item.id, data.item.role[0].id)"> Remove Role </b-button>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of {{ pagination.itemsLength }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePageSpecialItems({ page: $event, itemsPerPage: perPage })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormSelect, BFormInvalidFeedback, BPagination, BTable, BDropdown, BDropdownItem, BBadge } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useAccessLevel from "./useAccessLevelList";
import UserRepository from "@/abstraction/repository/userRepository";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AssignRoleListFilters from "./AssignRoleListFilters.vue";
const userRepository = new UserRepository();
const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        AssignRoleListFilters,
        BBadge,
        BDropdown,
        BDropdownItem,
        BTab,
        BTable,
        BFormSelect,
        BPagination,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
        ToastificationContent,
        AssignRoleListFilters,
    },
    directives: {
        Ripple,
    },
    setup(props, context) {
        let blankForm = {};

        const accessLevelId = ref(null);

        const form = ref(JSON.parse(JSON.stringify(blankForm)));
        const roles = ref();
        // Register module
        const { addRole, fetchRoleList, assignRoleToUser, tableColumns, perPage, currentPage, dataMeta, perPageOptions, searchQuery, sortBy, isSortDirDesc, pagination, userRole, fetchUserRoles, changePageUserRoles, removeRoleFromUser } = useAccessLevel();
        const users = ref();
        const userIds = ref();
        const roleId = ref();

        onMounted(async () => {
            fetchUserRoles().then((res) => {
                console.log(userRole);
            });
        });
        fetchRoleList().then((res) => {
            roles.value = res.value;
        });
        const filterUsers = async (search) => {
            const filters = {
                user_name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            try {
                loadUsers(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
            }
        };
        const loadUsers = async (param = {}) => {
            const resource = await userRepository.index(param);
            users.value = resource.data;
        };
        loadUsers();
        const onSubmit = async () => {
            addRole(form.value).then((res) => {
                router.push({ name: "access-level-list" });
            });
        };

        // Reset Validation
        const resetData = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };
        const asignePermission = () => {
            console.log(userIds.value.id, roleId.value.id);
            let data = {
                user_id: userIds.value.id,
                role_id: roleId.value.id,
            };
            assignRoleToUser(data).then((res) => {
                fetchUserRoles()
                if (res == false) {
                    context.root.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error",
                            icon: "BellIcon",
                            text: "Sorry! There is An Error...",
                            variant: "danger",
                        },
                    });
                } else {
                    context.root.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Updated",
                            icon: "BellIcon",
                            text: "Role Assigned Successfully👋",
                            variant: "success",
                        },
                    });
                }
            });
        };
        const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);
        const changePageSpecialItems = async (paginate) => {
            console.log(paginate);
            await fetchUserRoles(paginate);
        };
        const removeRoleHandler = async (userId, roleId) => {
            let data = {
                user_id: userId,
                role_id: roleId,
            };
            console.log(data);
            removeRoleFromUser(data).then((res) => {
                // location.reload();
                fetchUserRoles()
            });
        };
        return {
            removeRoleHandler,
            form,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            required,
            accessLevelId,
            users,
            userIds,
            roleId,
            filterUsers,
            roles,
            asignePermission,
            tableColumns,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            pagination,
            userRole,
            changePageUserRoles,
            changePageSpecialItems,
            fetchUserRoles,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
